<template>
  <transition name="fade">
    <div>
        <div class="card p-0 mb-5" :class="{ 'back-red': state.contratSelect.cont_x_archive }">
            <div class="card-header" id="kt_activities_header">
                <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
                    <img :src="imgConst(state.contratSelect.const_seq)"  v-if="imgConst(state.contratSelect.const_seq) && state.contratSelect.const_seq" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" class="m-1" />
                    <div v-else class="badge text-gray-900" style="width: auto; min-height: 40px; min-width:75px; background-color: #ececec; float: left;margin-right:10px;" >{{ state.contratSelect.constructeur }}</div>

                    <div v-if ="!state.contratSelect.cont_x_archive">
                      <div v-if="dateDepasse(state.contratSelect.fsh_date_fin)==-1" class=" m-1 me-5 text-dark fs-5 bg-light-success p-3">
                          <span class="svg-icon svg-icon-1 svg-icon-success me-3"><inline-svg src="media/icons/duotune/arrows/arr016.svg" /></span>
                          <span>{{$t("CONTRAT ACTIF")}}</span>
                      </div>
                      <div v-else class=" m-1 me-5 text-dark fs-5 bg-light-danger p-3">
                          <span class="svg-icon svg-icon-1 svg-icon-danger me-3"><inline-svg src="media/icons/duotune/arrows/arr015.svg" /></span>
                          <span>{{$t("CONTRAT INACTIF")}}</span>
                      </div>
                    </div>
                    <div v-if ="state.contratSelect.cont_x_archive">
                      <div class=" m-1 me-5 text-dark fs-5 bg-light-danger p-3">
                          <span class="svg-icon svg-icon-1 svg-icon-danger me-3"><inline-svg src="media/icons/duotune/files/fil007.svg" /></span>
                          <span>{{$t("CONTRAT ARCHIVE")}}</span>
                      </div>
                    </div>

                    <h4 style="margin-top: 7px;">
                        <span v-if="state.contratSelect.cont_libelle">{{ state.contratSelect.cont_libelle }}</span>
                        <span v-else><i>{{$t("Contrat sans libellé")}}</i></span>
                        <span v-if="state.contratSelect.fsh_date_fin"> - {{$t("Echéance au")}} {{ formatDate(state.contratSelect.fsh_date_fin) }}</span>
                        <span v-else><i>{{$t("Echéance inconnue")}}</i></span>
                    </h4>
                </div>
                <div class="card-toolbar">
                    <el-popconfirm v-if="!(dateDepasse(state.contratSelect.fsh_date_fin)==-1) && !state.contratSelect.cont_x_archive" :title="$t('Voulez-vous vraiment archiver ce contrat ?')" :confirm-button-text="$t('OUI')" :cancel-button-text="$t('Non, ne pas archiver')" @confirm="confirmArchive(1)">
                      <template #reference>
                        <button  type="button" class="btn btn-sm btn-text-icon btn-danger btn-active-light m-1">
                            <span class="svg-icon svg-icon-1">
                                <inline-svg src="media/icons/duotune/files/fil007.svg" />
                            </span>
                            <span>
                                {{$t("Archiver le contrat")}}
                            </span>
                        </button>
                      </template>
                    </el-popconfirm>   
                    <el-popconfirm v-if="!(dateDepasse(state.contratSelect.fsh_date_fin)==-1) && state.contratSelect.cont_x_archive" :title="$t('Voulez-vous vraiment désarchiver ce contrat ?')" :confirm-button-text="$t('OUI')" :cancel-button-text="$t('Non, ne pas désarchiver')" @confirm="confirmArchive(0)">
                      <template #reference>
                        <button  type="button" class="btn btn-sm btn-text-icon btn-white btn-active-light m-1">
                            <span class="svg-icon svg-icon-1">
                                <inline-svg src="media/icons/duotune/files/fil008.svg" />
                            </span>
                            <span>
                                {{$t("Désarchiver le contrat")}}
                            </span>
                        </button>
                      </template>
                    </el-popconfirm>               

                    <button type="button" class="btn btn-sm btn-text-icon btn-primary btn-active-light m-1" @click="state.dialogFormVisible = true">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="media/icons/duotune/general/gen029.svg" />
                        </span>
                        <span>
                            {{$t("Demande de renouvellement")}}
                        </span>
                    </button>
                    <el-dialog v-model="state.dialogFormVisible" :title="`Demande de renouvellement concernant votre contrat ${ state.contratSelect.constructeur } N° ${ state.contratSelect.fsh_contract_number }`">
                      <span>{{$t("Cette demande sera envoyée directement à votre interlocteur Stillnetwork, qui reprendra contact avec vous rapidement")}}<br>{{$t("afin de faire le point sur votre besoin.")}}</span><br><br>
                      <el-form :model="formDemande">
                        <el-form-item>
                          <el-input v-model="formDemande.desc" :autosize="{ minRows: 6, maxRows: 12}"  type="textarea" :placeholder="$t(`Facultatif : Veuillez saisir ici un complément dinformation concernant votre demande de renouvellement.`)" />
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span class="dialog-footer">
                          <el-button @click="state.dialogFormVisible = false">{{$t("Annuler")}}</el-button>
                          <el-button type="primary" @click="notifContratSuppor$t(formDemande)">{{$t("Envoyer votre demande")}}</el-button>
                        </span>
                      </template>
                    </el-dialog>
                    <button type="button" class="btn btn-sm btn-text-icon btn-success btn-active-light m-1 " @click="state.dialogCaseVisible = true">  <!-- @click="openCases()" -->
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="media/icons/duotune/general/gen015.svg" />
                        </span>    
                        <span>
                            {{$t("Ouvrir un ticket de support")}}
                        </span>
                    </button>
                    <el-dialog v-model="state.dialogCaseVisible" :title="$t('Ouvrir un case CISCO')">
                      <Cisco display='tabs' />
                    </el-dialog>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header border-0 pt-6">
                <a @click="openSubAllItem()" class="btn btn-sm btn-icon btn-primary" style="width: calc(0.5em + 1.1rem + 4px); height: calc(0.5em + 1.1rem + 4px);margin-top: 19px;" :title="$t('Déployer toute les références majeures')">
                    <i class="fas fa-plus fs-4 me-0"></i>
                </a>
                <SearchAndFilter
                    :listInitial="state.initialMyList"
                    :filters="tableFilters"
                    @returnSearch="state.myList = $event"
                    @returnLoadingDatatable="state.loadingDatatable = $event"
                    widthDecalage="30"
                    ExcelFilename="Stillnetwork_Detail_contrat"
                    :ExcelFormat="ExcelFormat"
                    :key="state.initialMyList"
                    :searchKeepParent="objKeepParent"
                ></SearchAndFilter>
            </div>
                <div class="card-body pt-0">
                    <div v-if="!state.loaderEnabled">
                        <div v-if="state.myList.length == 0" class=" alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10">
                            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                                <inline-svg src="media/icons/duotune/general/gen048.svg" />
                            </span>
                            <div class="d-flex flex-column">
                                <h4 class="mb-1 text-dark">{{$t("Aucun Contrat")}}</h4>
                                <span>{{$t("Il n'existe aucune ligne dans le contrat à afficher correspondant à vos critères de recherche.")}}</span>
                            </div>
                        </div>

                    </div>
                    <div style="width: 100%; height: 100%; text-align: center; padding-top: 100px; padding-bottom: 100px" v-if="state.loaderEnabled">
                        <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
                    </div>
                    
                    <Datatable
                        v-if="!state.loaderEnabled && state.myList && state.myList.length > 0"
                        :table-data="state.myList"
                        :table-header="tableHeader"
                        :enable-items-per-page-dropdown="false"
                        :rowsPerPage="150"
                        :empty-table-text="$t('Aucune ligne de contrat à afficher.')"
                        :key="state.loadingDatatable"
                    >
                        <template v-slot:cell-fsh_product_description="{ row: data }">
                            <div :class="{ 'decSubItem': isRefMajeur(data)==0, 'MajeurItem': !isRefMajeur(data)==0  }" class="text-gray-800">
                                <div class="text-gray-500">
                                    <span class="w-150px fs-6 text-dark" v-if="isRefMajeur(data) > 1" style='margin-left:28px;'>{{ data.fsh_product_number }}</span>
                                    <span class="w-150px fs-6 text-dark" v-else style='margin-left:3px;'>{{ data.fsh_product_number }}</span>
                                </div>
                                <a v-if="isRefMajeur(data) > 1" @click="openSubItem(data)" class="btn btn-sm btn-icon btn-secondary" style="width: calc(0.5em + 1.1rem + 4px); height: calc(0.5em + 1.1rem + 4px);">
                                    <i v-if="data.opensub==0" class="fas fa-plus fs-4 me-0"></i>
                                    <i v-if="data.opensub==1" class="fas fa-minus fs-4 me-0"></i>
                                </a>
                                <span class="fs-7 text-gray-500">&nbsp;{{ data.fsh_product_description }}</span>
                                <span v-if="data.fsh_serial_number">
                                    <div v-if="isRefMajeur(data) > 1" style='margin-left:28px;'><i class="text-primary">&nbsp;{{$t("SN :")}} {{ data.fsh_serial_number }}</i></div>
                                    <div v-else style='margin-left:3px;'><i class="text-primary">&nbsp;{{$t("SN :")}} {{ data.fsh_serial_number }}</i></div>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell-fsh_date_debut="{ row: data }">
                            <span v-if="test_x_month(data.fsh_date_fin,3)">
                            <div class="badge text-dark w-125px bt-6 fs-7">
                                <span class="fs-8">{{$t("Du")}}</span> {{ formatDate(data.fsh_date_debut) }}<br><br><span class="fs-8">{{$t("au")}}</span> {{ formatDate(data.fsh_date_fin) }}
                            </div>
                            </span>
                            <span v-else>

                                <div v-if="dateDepasse(data.fsh_date_fin)==-1" class="badge badge-light-warning text-dark w-125px bt-6 fs-7">
                                    <span class="fs-8">{{$t("Du")}}</span> {{ formatDate(data.fsh_date_debut) }}<br><br><span class="fs-8">{{$t("au")}}</span> {{ formatDate(data.fsh_date_fin) }}
                                </div>
                                <div v-else class="badge badge-light-danger w-125px bt-6 fs-7">
                                       <span class="fs-8">{{$t("Du")}}</span> {{ formatDate(data.fsh_date_debut) }}<br><br><span class="fs-8">{{$t("au")}}</span> {{ formatDate(data.fsh_date_fin) }}
                                </div>

                            </span>
                        </template>
                        <template v-slot:cell-fsh_service_level="{ row: data }">
                            <span>
                            <div v-if="data.lsc_libelle_web" class="text-dark bt-7 fs-6">
                                {{ data.lsc_libelle_web }}
                            </div>
                            <div v-else class="text-dark bt-7 fs-6">
                                {{ data.fsh_service_level }}
                            </div>
                            </span>
                        </template>
                        <template v-slot:cell-fsh_fin_garantie="{ row: data }">
                            <span>
                            <div class="badge text-dark bt-7 fs-7" v-if="data.fsh_fin_garantie">
                                <span v-if="dateDepasse(data.fsh_fin_garantie)==-1" >
                                    <span class="svg-icon svg-icon-1hx svg-icon-primary me-2">
                                        <inline-svg src="media/icons/duotune/general/gen043.svg" />
                                    </span>
                                     <span class="fs-8 text-gray-700">{{$t("Garantie jusqu'au")}} {{ formatDate(data.fsh_fin_garantie) }}</span></span>
                                <span v-else class="text-dark">
                                    <span class="svg-icon svg-icon-1hx svg-icon-danger me-2">
                                        <inline-svg src="media/icons/duotune/general/gen044.svg" />
                                    </span>
                                    <span class="fs-8 text-gray-700">{{$t("Hors garantie depuis le")}} {{ formatDate(data.fsh_fin_garantie) }}</span></span>
                                
                            </div>
                            <span v-if="data.fsh_eos" class="badge text-danger bt-7 fs-8">
                              <span class="svg-icon svg-icon-1hx svg-icon-danger me-2">
                                <inline-svg src="media/icons/duotune/general/gen007.svg" />
                              </span>
                                {{$t("Matériel supporté jusqu'au")}} {{ formatDate(data.fsh_eos) }}
                            </span>
                            </span>
                        </template>
                        <template v-slot:cell-adr_seq="{ row: data }">
                            <span>
                            <div v-if="data.adr_seq" class="badge badge-secondary fs-7 text-gray-700 bt-7 fs-7">
                                <span class="svg-icon svg-icon-1hx svg-icon-dark me-2 fs-8">
                                  <inline-svg src="media/icons/duotune/general/gen018.svg" />
                                </span>
                                <span v-if="data.adr_libelle">{{ data.adr_libelle }}<br></span>
                                <span>{{ data.adr_ligne_1 }} {{ data.adr_cp }} {{ data.adr_ville }}</span>
                            </div>
                            <div v-else class="badge text-dark bt-7 fs-7">
                                <span>{{ data.fsh_site_adress_1 }} {{ data.fsh_site_cp }} {{ data.fsh_site_ville }}</span>
                            </div>
                            </span>
                        </template>
                    </Datatable>

                </div> 
        </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { ElNotification } from 'element-plus'
import Cisco from "@/views/still/contrats/supports/CISCO.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "contrats",
  components: {
    Datatable,
    SearchAndFilter,
    Cisco
  },
  props: {
    zContrat: Object,
  },
  
  emits: ["changeContrat"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const state = reactive({
      contratSelect: {},
      loaderEnabled: true,
      loadingDatatable: 0,
      initialMyList: [],
      myList: [] as any,
      dialogFormVisible: false,
      dialogCaseVisible: false,
    });
    const { t } = useI18n()

    const formDemande = reactive({
      desc: '',
    })

    const objKeepParent = {'parent': 'fsh_parent_instance', 'enfant': 'fsh_instance_number'};

    const tableHeader = ref([
      {
        name: "Produit",
        key: "fsh_product_description",
        sortable: false,
      },
      {
        name: "Période support",
        key: "fsh_date_debut",
        sortable: false,
      },
      {
        name: "Serv. Level",
        key: "fsh_service_level",
        sortable: false,
      },
      {
        name: "Garantie",
        key: "fsh_fin_garantie",
        sortable: false,
      },
      {
        name: "Site",
        key: "adr_seq",
        sortable: false,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Garantie",
        key: "type_garantie",
        label: "type_garantie",
      },
      {
        name: "Type de support",
        key: "type_support",
        label: "type_support",
      },
    ]);

    const ExcelFormat = ref({
      "Produit": "fsh_product_number",
      "Description" : "fsh_product_description",
      "Serial Number": "fsh_serial_number",
      "Instance Number" : "fsh_instance_number",
      "Parent Instance Number" : "fsh_parent_instance",
      "Période debut": "fsh_date_debut",  
      "Période fin": "fsh_date_fin",
      "Serv Level": "fsh_service_level",
      "Garantie": "fsh_fin_garantie",
      "Site": "fsh_site_ville",
    });

    const isRefMajeur = (d) => {
      const listTmp = state.myList.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.fsh_parent_instance === d.fsh_instance_number
      );
      return listTmp.length;
    }

    const openSubItem = (d) => {
      state.myList.forEach(el => {         
          if (el.fsh_instance_number === d.fsh_instance_number) {
            el.opensub = d.opensub == 0 ? 1 : 0;
          }
          if (el.fsh_parent === d.fsh_instance_number) {
            el.displayrow = d.opensub == 0 ? 1 : 2;
          }

        });      
    }

    const openSubAllItem = () => {
      state.myList.forEach(el => {         
          el.opensub = 1;
          el.displayrow = 1;
        });      
    }

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
    };

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_x_month = (d, nb) => {
      const currentDate = moment.default().add(nb, 'M');
      return moment.default(d) > currentDate;
    }

    const dateDepasse = (d) => {
      if (moment.default(d) > moment.default()) return -1;
      return 1
    };

    onMounted(async () => {
      const contratSelect: any = props.zContrat;
      state.contratSelect = contratSelect as any;
      if (contratSelect && contratSelect.code) {
        let myList = await mAxiosApi.prototype.getAxios("/getDetailContratSupport/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur);
        state.initialMyList = myList.results;
        state.myList = myList.results;  
        //console.log(contratSelect);
        state.loaderEnabled = false;
      }      
    });

    const notifContratSupport = async (data) => {
      const contratSelect: any = props.zContrat;
      mAxiosApi
        .post("notifContratSupport/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur, data)
        .then(() => {
          state.dialogFormVisible = false;  
          ElNotification({
            title: t("Succés"),
            message: t("Demande de renouvellement envoyée à votre interlocuteur Stillnetwork."),
            type: 'success',
          })
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const confirmArchive = async (type) => {
      const contratSelect: any = props.zContrat;
      mAxiosApi
        .post("arhiveContrat/" + contratSelect.code + '/' + contratSelect.cont_knum_constructeur + '/' + type)
        .then(() => {
          const contratSelectTmp: any = state.contratSelect;
          contratSelectTmp.cont_x_archive = type==1 ? 1 : null;
          emit("changeContrat", contratSelectTmp);
          ElNotification({
            title: t("Archivage"),
            message: t("Ce contrat est maintenant considéré comme") + ' ' + (contratSelectTmp.cont_x_archive == 1 ? t("archivé") : t("désarchivé")),
            type: 'success',
          })
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const openCases = () => {
      window.open('https://mycase.cloudapps.cisco.com/case', '_blank');
    }

    return {
      state,
      tableFilters,
      tableHeader,
      imgConst,
      ExcelFormat,
      isRefMajeur,
      openSubItem,
      openSubAllItem,
      formatDate,
      test_x_month,
      dateDepasse,
      formDemande,
      notifContratSupport,
      openCases,
      objKeepParent,
      confirmArchive,
    };
  
  },
});
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}


.demo-tabs > .el-tabs__content {
  padding : 32px;
  padding-top: 10px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}



</style>